:root {
  --font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --red-color: #ff2600;
}

html {
  font-size: 11pt;
}

body {
  font-family: var(--font-family-sans-serif);
}

.navbar-brand {
  font-size: 1rem;
}

.modal .modal-dialog.modal-xl {
  max-width: 1100px;
}

.list-group-flush .list-group-item:last-child {
  border-bottom: 0;
}

/* Force white background on each tooltip span */
.highcharts-tooltip span {
  background-color: white;
}

.highcharts-bubble-chart .highcharts-tooltip span {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  display: inline-block;
}

.font-normal {
  font-weight: 400;
}

.text-xs {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
