input[type='range'] {
  height: 26px;
  -webkit-appearance: none;
  margin: -5px 0 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #333333;
  border-radius: 3px;
  border: 0px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 10px;
  border-radius: 3px;
  background: #f12915;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #333333;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #333333;
  border-radius: 3px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 10px;
  border-radius: 3px;
  background: #f12915;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #333333;
  border: 0px solid #000000;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: #333333;
  border: 0px solid #000000;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 10px;
  border-radius: 3px;
  background: #f12915;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #333333;
}
input[type='range']:focus::-ms-fill-upper {
  background: #333333;
}
